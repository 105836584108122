:root {
  --colorPrimary: #334249;
  --colorPrimaryDark: #2e3c43;
  --colorAccent: #ca2c68;

  --primary_alpha_60: #3342498f;
  --primary_alpha: #cc334249;
  --accent_alpha_60: #ca2c699c;
  --white: #fff;
  --red: #c62828;
  --green: #21d62a;
  --white_alpha: #33ffffff;
  --gray: #b5b9c1;
  --black: #000;
  --gray_icon: #c8c7cf;
  --gray_background: #858e93;
}

body {
  background-color: var(--colorPrimaryDark);
}

input[type="text"],
input[type="password"],
select {
  outline: 0px;
  border: 0;
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  width: 100%;
  color: white;
  -webkit-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
}

select {
  text-align-last: center;
  text-align: center;
}

textarea {
  box-sizing: border-box;
  border: none;
  resize: none;
  width: 100%;
  color: white;
  background-color: transparent;
  overflow: auto;
  height: auto;
}

input[type="text"]::placeholder {
}

textarea::placeholder {
  color: white;
}

textarea:focus {
  outline: none;
}
